import React from "react";
import { OperationEnum } from "./enums/OperationEnum";
import { SupereadPermission, CodeExamyPermissons } from "./Permissions";

const QuestionPoolV2 = React.lazy(() => import("views/AdminSide/QuestionPool/QuestionPoolV2"));
const QuestionBooks = React.lazy(() => import("views/AdminSide/QuestionBooks/QuestionBooks"));
const PersonalBookLessonMeta = React.lazy(() =>
  import("views/AdminSide/PersonalBook/PersonalBookLessonMeta/PersonalBookLessonMeta")
);
const PersonalBook = React.lazy(() => import("views/AdminSide/PersonalBook/PersonalBook"));

const NearpodHomework_CU = React.lazy(() =>
  import("views/AdminSide/NearpodHomework/components/NearpodHomework_CU")
);
const NearpodHomework = React.lazy(() => import("views/AdminSide/NearpodHomework/NearpodHomework"));
const PDR = React.lazy(() => import("views/AdminSide/PDR/PDR"));
const MultipleContractPayment_CU = React.lazy(() =>
  import(
    "views/AdminSide/AccountingModule/Payment/components/MultipleContractPayment/MultipleContractPayment_CU"
  )
);

const Dsd = React.lazy(() => import("views/AdminSide/DSD/Dsd"));
const Dsd_CU = React.lazy(() => import("views/AdminSide/DSD/components/Dsd_CU"));

const AtoZ = React.lazy(() => import("views/AdminSide/AtoZReport/AtoZReport"));
const AtoZ_CU = React.lazy(() => import("views/AdminSide/AtoZReport/components/AtoZReport_CU"));

const AdditionalBuildingCU = React.lazy(() =>
  import("views/AdminSide/AdditionalBuilding/components/AdditionalBuildingCU")
);
const AdditionalBuilding = React.lazy(() =>
  import("views/AdminSide/AdditionalBuilding/AdditionalBuilding")
);
const StudentTransferRequestCU = React.lazy(() =>
  import("views/AdminSide/StudentTransferRequest/components/StudentTransferRequestCU")
);
const StudentTransferRequest = React.lazy(() =>
  import("views/AdminSide/StudentTransferRequest/StudentTransferRequest")
);
// const YKSPreferenceMatic = React.lazy(() =>
//   import("views/AdminSide/ExamModule/PreferenceMatic/YKS/YKSPreferenceMatic")
// );
const LGSPreferenceMatic = React.lazy(() =>
  import("views/AdminSide/ExamModule/PreferenceMatic/LGS/LGSPreferenceMatic")
);

//#region IMPORTS
const IntegrationShareCU = React.lazy(() =>
  import("views/AdminSide/IntegrationShare/components/IntegrationShareCU")
);
const IntegrationShare = React.lazy(() =>
  import("views/AdminSide/IntegrationShare/IntegrationShare")
);
const Outside = React.lazy(() => import("views/AdminSide/Outside/Outside"));
const VideoFeedbackAdmin = React.lazy(() => import("views/AdminSide/VideoFeedback/VideoFeedback"));
const SessionReports = React.lazy(() =>
  import("views/AdminSide/Session/SessionReports/SessionReports")
);
const Session_CU = React.lazy(() => import("views/AdminSide/Session/Session_CU/Session_CU"));
const Session = React.lazy(() => import("views/AdminSide/Session/Session"));
const AllExamResults = React.lazy(() =>
  import("views/AdminSide/ExamModule/AllExamResults/AllExamResults")
);
const ExamEtude = React.lazy(() => import("views/AdminSide/ExamModule/ExamEtude/ExamEtude"));
const SchoolFoodProgram = React.lazy(() =>
  import("views/AdminSide/SchoolFoodProgram/SchoolFoodProgram")
);
const AttendanceSchool = React.lazy(() => import("views/AdminSide/Attendance/AttendanceSchool"));
const BasicHomework_CU = React.lazy(() =>
  import("views/AdminSide/BasicHomework/components/BasicHomeworkCU")
);

const DigitalLibrary = React.lazy(() => import("views/AdminSide/DigitalLibrary/DigitalLibrary"));

const Library = React.lazy(() => import("views/AdminSide/Library/Library"));
const Library_CU = React.lazy(() => import("views/AdminSide/Library/components/BookLibrary_CU"));

const BasicHomework = React.lazy(() => import("views/AdminSide/BasicHomework/BasicHomework"));
const DatHistory = React.lazy(() => import("views/AdminSide/ExamModule/DatHistory/DatHistory"));
const Teacher = React.lazy(() => import("views/AdminSide/Teacher/Teacher"));
const Etude = React.lazy(() => import("views/AdminSide/Etude/Etude"));
const QuestionSolution = React.lazy(() =>
  import("views/AdminSide/QuestionSolution/QuestionSolution")
);
const QuestionSolution_CU = React.lazy(() =>
  import("views/AdminSide/QuestionSolution/components/QuestionSolutionCU")
);
const Etude_CU = React.lazy(() => import("views/AdminSide/Etude/components/Etude_CU"));
// const FlowArea = React.lazy(() => import("views/AdminSide/FlowArea/FlowArea"));
const PreRegistrationCU = React.lazy(() =>
  import("views/AdminSide/PreRegistration/components/PreRegistrationCU")
);
const EvaluationResultMSU = React.lazy(() =>
  import("views/AdminSide/EvaluationResult/EvaluationResultMSU")
);
const EvaluationResultMSU_CU = React.lazy(() =>
  import("views/AdminSide/EvaluationResult/components/EvaluationResultMSU_CU")
);
const EvaluationResult = React.lazy(() =>
  import("views/AdminSide/EvaluationResult/EvaluationResult")
);
const EvaluationResult_CU = React.lazy(() =>
  import("views/AdminSide/EvaluationResult/components/EvaluationResult_CU")
);
// const CounsellingDetail_CU = React.lazy(() =>
//   import("views/AdminSide/Counselling/components/CounsellingDetail/CounsellingDetail_CU")
// );

const PreRegistration = React.lazy(() => import("views/AdminSide/PreRegistration/PreRegistration"));
const Counselling = React.lazy(() => import("views/AdminSide/Counselling/Counselling"));
const Counselling_CU = React.lazy(() =>
  import("views/AdminSide/Counselling/components/Counselling_CU")
);
const OverdueInstallments = React.lazy(() =>
  import("views/AdminSide/AccountingModule/OverdueInstallments/OverdueInstallments")
);
const UsersProfilePage = React.lazy(() => import("views/Common/UserProfile/UsersProfilePage"));
const ExamReport = React.lazy(() => import("views/AdminSide/ExamModule/ExamReports/ExamReport"));
const QuotaReport = React.lazy(() =>
  import("views/AdminSide/AccountingModule/Reports/QuotaReport/QuotaReport")
);
const PaymentReport = React.lazy(() =>
  import("views/AdminSide/AccountingModule/Reports/PaymentReport/PaymentReport")
);
const ContractReport = React.lazy(() =>
  import("views/AdminSide/AccountingModule/Reports/ContractReport/ContractReport")
);
const ContractLog = React.lazy(() => import("views/AdminSide/AccountingModule/Log/ContractLog"));
const DiscountItem_CU = React.lazy(() =>
  import("views/AdminSide/AccountingModule/DiscountItem/components/DiscountItem_CU")
);
const DiscountItem = React.lazy(() =>
  import("views/AdminSide/AccountingModule/DiscountItem/DiscountItem")
);
const FeeItemSet = React.lazy(() =>
  import("views/AdminSide/AccountingModule/FeeItemSet/FeeItemSet")
);
const FeeItemSet_CU = React.lazy(() =>
  import("views/AdminSide/AccountingModule/FeeItemSet/components/FeeItemSet_CU")
);
const FeeItem_CU = React.lazy(() =>
  import("views/AdminSide/AccountingModule/FeeItem/components/FeeItem_CU")
);
const FeeItem = React.lazy(() => import("views/AdminSide/AccountingModule/FeeItem/FeeItem"));
const TrackLightReportCard = React.lazy(() =>
  import("views/AdminSide/TrackLight/ReportCard/TrackLightReportCard")
);
const TrackLightReportCard_CU = React.lazy(() =>
  import("views/AdminSide/TrackLight/ReportCard/components/TrackLightReportCard_CU")
);
const TrackLightEarning = React.lazy(() =>
  import("views/AdminSide/TrackLight/Earning/TrackLightEarning")
);
const TrackLightEarning_CU = React.lazy(() =>
  import("views/AdminSide/TrackLight/Earning/components/TrackLightEarning_CU")
);
const FinancialAccount = React.lazy(() =>
  import("views/AdminSide/AccountingModule/FinancialAccount/FinancialAccount")
);
const FinancialYear = React.lazy(() =>
  import("views/AdminSide/AccountingModule/FinancialYear/FinancialYear")
);
const AcademicCalendar_CU = React.lazy(() =>
  import("views/AdminSide/AcademicCalendar/components/AcademicCalendar_CU")
);
const Community = React.lazy(() => import("views/AdminSide/Community/Community"));
const Community_CU = React.lazy(() => import("views/AdminSide/Community/components/Community_CU"));
const StudentCU = React.lazy(() => import("views/AdminSide/Student/components/StudentCU"));
const Campus = React.lazy(() => import("views/AdminSide/Campus/Campus"));
const Campus_CU = React.lazy(() => import("views/AdminSide/Campus/components/Campus_CU"));
const Conservator = React.lazy(() => import("views/AdminSide/Conservator/Conservator"));
const ConservatorCU = React.lazy(() =>
  import("views/AdminSide/Conservator/components/ConservatorCU")
);
const ContentProducer = React.lazy(() => import("views/AdminSide/ContentProducer/ContentProducer"));
const ContentProducer_CU = React.lazy(() =>
  import("views/AdminSide/ContentProducer/components/ContentProducer_CU")
);
const ContentProducerUser_CU = React.lazy(() =>
  import("views/AdminSide/ContentProducer/components/ContentProducerUser_CU")
);
const Personel = React.lazy(() => import("views/AdminSide/Personel/Personel"));
const PersonelCU = React.lazy(() => import("views/AdminSide/Personel/components/PersonnelCU"));
const School = React.lazy(() => import("views/AdminSide/School/School"));
const School_CU = React.lazy(() => import("views/AdminSide/School/components/School_CU"));
const SharePack = React.lazy(() => import("views/AdminSide/SharePack/SharePack"));
const SharePack_CU = React.lazy(() => import("views/AdminSide/SharePack/components/SharePack_CU"));
const Curriculum = React.lazy(() => import("views/AdminSide/Curriculum/Curriculum"));
const Operation = React.lazy(() => import("views/AdminSide/Operation/Operation"));
const Operation_CU = React.lazy(() => import("views/AdminSide/Operation/components/Operation_CU"));
const Role = React.lazy(() => import("views/AdminSide/Role/Role"));
const Role_CU = React.lazy(() => import("views/AdminSide/Role/components/Role_CU"));
const Level = React.lazy(() => import("views/AdminSide/Level/Level"));
const Level_CU = React.lazy(() => import("views/AdminSide/Level/components/Level_CU"));
const BaseBranch = React.lazy(() => import("views/AdminSide/Branch/BaseBranch"));
const BaseBracnh_CU = React.lazy(() => import("views/AdminSide/Branch/components/BaseBranch_CU"));
const Branch = React.lazy(() => import("views/AdminSide/Branch/Branch"));
const Branch_CU = React.lazy(() => import("views/AdminSide/Branch/components/Branch_CU"));
const Course = React.lazy(() => import("views/AdminSide/Course/Course"));
const Course_CU = React.lazy(() => import("views/AdminSide/Course/components/Course_CU"));
const Student = React.lazy(() => import("views/AdminSide/Student/Student"));
const LiveCourse = React.lazy(() => import("views/AdminSide/LiveCourse/LiveCourse"));
const LiveCourse_CU = React.lazy(() =>
  import("views/AdminSide/LiveCourse/components/LiveCourse_CU")
);
const Homework = React.lazy(() => import("views/AdminSide/Homework/Homework"));
const Homework_CU = React.lazy(() => import("views/AdminSide/Homework/components/Homework_CU"));
const Test = React.lazy(() => import("views/AdminSide/Test/Test"));
const Test_CU = React.lazy(() => import("views/AdminSide/Test/components/Test_CU"));
const Corporation = React.lazy(() => import("views/AdminSide/Corporation/Corporation"));
const Corporation_CU = React.lazy(() =>
  import("views/AdminSide/Corporation/components/Corporation_CU")
);
const CenterGroup = React.lazy(() => import("views/AdminSide/CenterGroup/CenterGroup"));
const CenterGroup_CU = React.lazy(() =>
  import("views/AdminSide/CenterGroup/components/CenterGroup_CU")
);
const Classroom = React.lazy(() => import("views/AdminSide/Classroom/Classroom"));
const Classroom_CU = React.lazy(() => import("views/AdminSide/Classroom/components/Classroom_CU"));
const Group_CU = React.lazy(() => import("views/AdminSide/Group/components/Group_CU"));
const Group = React.lazy(() => import("views/AdminSide/Group/Group"));
const OpticalForm = React.lazy(() => import("views/AdminSide/ExamModule/OpticalForm/OpticalForm"));
const ExamTemplate = React.lazy(() =>
  import("views/AdminSide/ExamModule/ExamTemplate/ExamTemplate")
);
const ExamTemplate_CU = React.lazy(() =>
  import("views/AdminSide/ExamModule/ExamTemplate/components/ExamTemplate_CU")
);
const PdfProject = React.lazy(() => import("views/AdminSide/ExamModule/PdfProject/PdfProject"));
const PdfCrop = React.lazy(() => import("views/AdminSide/ExamModule/PdfCrop/PdfCrop"));
const QuestionPool = React.lazy(() => import("views/AdminSide/QuestionPool/QuestionPool"));
const Question_CU = React.lazy(() => import("views/AdminSide/QuestionPool/components/Question_CU"));
const Exam = React.lazy(() => import("views/AdminSide/ExamModule/Exam/Exam"));
const Exam_CU = React.lazy(() => import("views/AdminSide/ExamModule/Exam/components/Exam_CU"));
const QuestionCart = React.lazy(() =>
  import("views/AdminSide/QuestionPool/components/QuestionCart/QuestionCart")
);
const ExamEvaluation = React.lazy(() =>
  import("views/AdminSide/ExamModule/ExamEvaluation/ExamEvaluation")
);
const Contract = React.lazy(() => import("views/AdminSide/AccountingModule/Contract/Contract"));
const Contract_CU = React.lazy(() =>
  import("views/AdminSide/AccountingModule/Contract/Contract_CU/Contract_CU")
);
const Payment = React.lazy(() => import("views/AdminSide/AccountingModule/Payment/Payment"));
const Payment_CU = React.lazy(() =>
  import("views/AdminSide/AccountingModule/Payment/components/Payment_CU")
);
const Club = React.lazy(() => import("views/AdminSide/Club/Club"));
const Club_CU = React.lazy(() => import("views/AdminSide/Club/components/Club_CU"));

const Message = React.lazy(() => import("views/AdminSide/Announcement/Message"));
const NextCloud = React.lazy(() => import("views/AdminSide/NextCloud/NextCloud"));
const Superead = React.lazy(() => import("views/AdminSide/Superead/Superead"));
const Dashboard = React.lazy(() => import("views/Common/Dashboard/Dashboard"));
const CodeExamy = React.lazy(() => import("views/AdminSide/CodeExamy/CodeExamy"));

const Rooms = React.lazy(() => import("views/AdminSide/Rooms/Rooms"));
const Rooms_CU = React.lazy(() => import("views/AdminSide/Rooms/components/Rooms_CU"));

const SittingPlan = React.lazy(() => import("views/AdminSide/ExamModule/SittingPlan/SittingPlan"));
const SittingPlan_CU = React.lazy(() =>
  import("views/AdminSide/ExamModule/SittingPlan/components/SittingPlan_CU")
);
// const ReadingMachine = React.lazy(() => import("views/AdminSide/School/components/ReadingMachine"));
const SinavGlobal = React.lazy(() => import("views/AdminSide/School/components/SinavGlobal"));
const Matific = React.lazy(() => import("views/AdminSide/School/components/Matific"));

const SurveyOption = React.lazy(() =>
  import("views/AdminSide/SurveyModule/SurveyOption/SurveyOption")
);
const SurveyOption_CU = React.lazy(() =>
  import("views/AdminSide/SurveyModule/SurveyOption/components/SurveyOption_CU")
);

const SurveyOptionSet = React.lazy(() =>
  import("views/AdminSide/SurveyModule/SurveyOptionSet/SurveyOptionSet")
);
const SurveyOptionSet_CU = React.lazy(() =>
  import("views/AdminSide/SurveyModule/SurveyOptionSet/components/SurveyOptionSet_CU")
);

const SurveyQuestion = React.lazy(() =>
  import("views/AdminSide/SurveyModule/SurveyQuestion/SurveyQuestion")
);
const SurveyQuestion_CU = React.lazy(() =>
  import("views/AdminSide/SurveyModule/SurveyQuestion/components/SurveyQuestion_CU")
);

const EvalutionSurvey = React.lazy(() =>
  import("views/AdminSide/SurveyModule/EvalutionSurvey/EvalutionSurvey")
);

const PDRFormTemplate_CU = React.lazy(() =>
  import("views/AdminSide/PDR/PDRFormTemplate/components/PDRFormTemplate_CU")
);

const PDRFormList = React.lazy(() => import("views/AdminSide/PDR/PDRFormTemplate/PDRFormList"));

const EvalutionSurvey_CU = React.lazy(() =>
  import("views/AdminSide/SurveyModule/EvalutionSurvey/components/EvalutionSurvey_CU")
);

const LiveExamyYks = React.lazy(() => import("views/AdminSide/LiveExamyYks/LiveExamyYks"));
const LiveExamyYks_CU = React.lazy(() =>
  import("views/AdminSide/LiveExamyYks/components/LiveExamyYks_CU")
);
const InstantMessage = React.lazy(() => import("views/AdminSide/InstantMessage/InstantMessage"));

const LivexamyModerator = React.lazy(() =>
  import("views/AdminSide/LivexamyModerator/LivexamyModerator")
);

const LivexamyQuestionSepet = React.lazy(() =>
  import("views/AdminSide/LivexamyModerator/components/QuestionSepet/components/QuestionSepetCU")
);

const CaseOperations = React.lazy(() => import("views/AdminSide/CaseOperations/CaseOperations"));
//TODO: eski livexamy daha sonra silinecek
// const LiveExamy = React.lazy(() => import("views/StudentSide/LiveExamy/LiveExamy"));

const PersonalitySurvey = React.lazy(() =>
  import("views/AdminSide/PersonalitySurvey/PersonalitySurvey")
);
const PersonalitySurveyCU = React.lazy(() =>
  import("views/AdminSide/PersonalitySurvey/components/PersonalityInventory_CU")
);

const SelfIdCurriculum = React.lazy(() =>
  import("views/AdminSide/SelfIdAdmin/SelfIdCurriculum/SelfIdCurriculum")
);
const SelfIdStudentTrack = React.lazy(() =>
  import("views/AdminSide/SelfIdAdmin/SelfIdStudentTrack/SelfIdStudentTrack")
);
const SelfIdTaskList = React.lazy(() =>
  import("views/AdminSide/SelfIdAdmin/SelfIdTask/SelfIdTask")
);
const SelfIdCurriculumCU = React.lazy(() =>
  import("views/AdminSide/SelfIdAdmin/SelfIdCurriculum/components/SelfIdCurriculum_CU")
);

const ModuleSolutionModerator = React.lazy(() =>
  import("views/Common/ModuleSolution/components/ModuleSolutionModerator")
);
const ModuleSolutionModeratorCU = React.lazy(() =>
  import("views/Common/ModuleSolution/components/ModuleSolutionCU")
);

//#endregion IMPORTS

const RouteList = [
  //#region COMMON OPERATIONS
  {
    id: "6164b5dcc783c144a031a765",
    code: [],
    name: "Ana Sayfa",
    icon: "/media/svg/topMenu/applications.svg",
    path: "/dashboard",
    component: Dashboard,
    menu: true,
    common: true,
    showParent: true
  },
  // {
  //   id: "6232c52ec783c144a031a888",
  //   code: [],
  //   name: "Livexamy",
  //   icon: "/media/svg/topMenu/livexamy.svg",
  //   path: "/livexamy",
  //   component: LiveExamy,
  //   menu: true,
  //   common: true,
  //   showParent: true
  // },
  {
    id: "264b5dcc783c1441fh3ha765",
    code: [OperationEnum.COMMON_PERSONNEL],
    name: "Livexamy",
    icon: "/media/svg/topMenu/livexamy.svg",
    path: "/livexamy-premium",
    component: LivexamyModerator,
    menu: true
  },
  {
    id: "264b5dcc783c1441fh3ha765",
    code: [OperationEnum.LIVEXAMY_CHANNEL_1_MODERATOR, OperationEnum.LIVEXAMY_CHANNEL_2_MODERATOR],
    name: "Livexamy Question Sepet",
    icon: "",
    path: "/livexamy-premium/question-sepet",
    component: LivexamyQuestionSepet
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [],
    name: "Nextcloud",
    icon: "/media/menuItems/cloud.png",
    path: "/nextcloud",
    component: NextCloud,
    menu: true,
    topMenuParentId: "top-menu-applications"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [],
    name: "Superead",
    icon: "/media/menuItems/superead.png",
    path: "/superead",
    component: Superead,
    menu: true,
    common: true,
    showParent: true,
    permissionFun: SupereadPermission,
    topMenuParentId: "top-menu-applications"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SOLUTION_VIDEO_ADMIN],
    name: "Modul Çözümleri",
    icon: "/media/menuItems/",
    path: "/module-solutions",
    component: ModuleSolutionModerator,
    menu: true,
    topMenuParentId: "top-menu-applications"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SOLUTION_VIDEO_ADMIN],
    name: "Modul Çözümleri Ekle",
    icon: "/media/menuItems/",
    path: "/module-solutions/add",
    component: ModuleSolutionModeratorCU,
    menu: false,
    topMenuParentId: "top-menu-applications"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SOLUTION_VIDEO_ADMIN],
    name: "Modul Çözümleri Düzenle",
    icon: "/media/menuItems/",
    path: "/module-solutions/edit",
    component: ModuleSolutionModeratorCU,
    menu: false,
    topMenuParentId: "top-menu-applications"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.CODE_EXAMY],
    permission: CodeExamyPermissons,
    name: "Code Examy",
    icon: "/media/bottom-menu/prefrobot.png",
    path: "/codeexamy",
    component: CodeExamy,
    menu: true,
    topMenuParentId: "top-menu-applications"
  },
  // {
  //   id: "6264b5dcc783a144c031c765",
  //   code: [OperationEnum.CLASS_VIEWER],
  //   external: ["READING_MACHINE"],
  //   name: "Sınav Dijital",
  //   icon: "/media/bottom-menu/dijital.png",
  //   path: "/sinav-digital",
  //   component: ReadingMachine,
  //   menu: false,
  //   topMenuParentId: "top-menu-applications"
  // },
  {
    id: "6264b5dcc783a144ccc1c765",
    code: [OperationEnum.CLASS_VIEWER],
    external: ["Matific2022"],
    name: "Matific",
    icon: "/media/client-logos/matific.png",
    path: "/matific",
    component: Matific,
    menu: true,
    topMenuParentId: "top-menu-applications"
  },
  {
    id: "6264100cc783a144c031c765",
    code: [OperationEnum.CLASS_VIEWER],
    external: ["SINAV_GLOBAL"],
    name: "Sınav GLOBAL",
    icon: "/media/bottom-menu/global.png",
    path: "/sinav-global",
    component: SinavGlobal,
    menu: true,
    topMenuParentId: "top-menu-applications"
  },
  // {
  //   id: "6264b5dcc783c144a031a765",
  //   code: [],
  //   name: "Akış",
  //   icon: "/media/menuItems/96.png",
  //   path: "/flow-area",
  //   component: FlowArea,
  //   menu: true,
  //   common: true
  // },
  {
    id: "6264b5dcc783c144a031a765",
    code: [],
    name: "Profilim",
    icon: "/media/bottom-menu/exam.png",
    path: "/user-profile/:tab",
    component: UsersProfilePage,
    menu: false,
    common: true
  },
  {
    id: "6264100cc783a144c031c765",
    code: [OperationEnum.INTEGRATION_ADMIN],
    name: "Entegrasyon Paylaş",
    icon: "/media/bottom-menu/global.png",
    path: "/integration-share",
    component: IntegrationShare,
    menu: true,
    topMenuParentId: "top-menu-applications"
  },
  {
    id: "6264100cc783a144c031c765",
    code: [OperationEnum.INTEGRATION_ADMIN],
    name: "Entegrasyon Paylaşımı Ekle",
    icon: "/media/bottom-menu/global.png",
    path: "/integration-share/add",
    component: IntegrationShareCU
  },
  {
    id: "6264100cc783a144c031c765",
    code: [OperationEnum.INTEGRATION_ADMIN],
    name: "Entegrasyon Paylaşımı Düzenle",
    icon: "/media/bottom-menu/global.png",
    path: "/integration-share/edit",
    component: IntegrationShareCU
  },
  {
    id: "264b5dcc783c1441fh3ha765-5",
    code: [OperationEnum.COMMON_PERSONNEL],
    name: "Dijital Kütüphane",
    icon: "/media/menuItems/...png",
    path: "/digital-library",
    component: DigitalLibrary,
    menu: true,
    topMenuParentId: "top-menu-applications"
  },
  {
    id: "345674b5dcc783c1441fh3ha765-5",
    code: [OperationEnum.PERSONAL_BOOK_ADMIN],
    name: "Kişiye Özel Kitap",
    icon: "/media/menuItems/...png",
    path: "/personal-book",
    component: PersonalBook,
    menu: true,
    topMenuParentId: "top-menu-applications"
  },
  {
    id: "364u46u4dcc783c1441fh3ha765-5",
    code: [OperationEnum.COMMON_PERSONNEL],
    name: "Kişiye Özel Kitap Ders Ayarları",
    icon: "/media/menuItems/...png",
    path: "/personal-book/lesson",
    component: PersonalBookLessonMeta,
    menu: false
  },
  // {
  //   id: "6264b5dcc783c144a031a765",
  //   code: [OperationEnum.SELECTION_ADMIN],
  //   name: "YKS Tercih Robotu",
  //   icon: "/media/menuItems/tercih.png",
  //   path: "/preference-matic-yks",
  //   component: YKSPreferenceMatic,
  //   menu: true,
  //   topMenuParentId: "top-menu-applications"
  // },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SELECTION_ADMIN],
    name: "LGS Tercih Robotu",
    icon: "/media/menuItems/tercih.png",
    path: "/preference-matic-lgs",
    component: LGSPreferenceMatic,
    menu: true,
    topMenuParentId: "top-menu-applications"
  },
  // {
  //   id: "6264b5dcc783c144a031a765",
  //   code: [OperationEnum.DASHBOARD_ADMIN],
  //   name: "Dashboard",
  //   // icon: "/media/bottom-menu/exam.png",
  //   path: "/dashboard",
  //   component: <></>,
  //   menu: true
  // },
  //#endregion COMMON OPERATIONS

  //#region CORPORATION OPERATIONS
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.CORPORATION_VIEWER],
    name: "Marka",
    icon: "/media/menuItems/38.png",
    path: "corporation-operations/corporation",
    component: Corporation,
    menu: true,
    parentId: "corporation-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Marka Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "corporation-operations/corporation/add",
    component: Corporation_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Marka Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "corporation-operations/corporation/edit",
    component: Corporation_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.CENTER_GROUP_ADMIN, OperationEnum.CENTER_GROUP_VIEWER],
    name: "Merkez Grup",
    icon: "/media/menuItems/67.png",
    path: "corporation-operations/center-group",
    component: CenterGroup,
    menu: true,
    parentId: "corporation-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.CENTER_GROUP_ADMIN],
    name: "Merkez Grup Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "corporation-operations/center-group/add",
    component: CenterGroup_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.CENTER_GROUP_ADMIN],
    name: "Merkez Grup Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "corporation-operations/center-group/edit",
    component: CenterGroup_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION, OperationEnum.COMMUNITY_VIEWER],
    name: "Topluluk",
    icon: "/media/menuItems/topluluk.png",
    path: "corporation-operations/community",
    component: Community,
    menu: true,
    parentId: "corporation-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Topluluk Ekle",
    icon: "/media/menuItems/topluluk.png",
    path: "corporation-operations/community/add",
    component: Community_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Topluluk Düzenle",
    icon: "/media/menuItems/topluluk.png",
    path: "corporation-operations/community/edit",
    component: Community_CU,
    menu: false
  },
  //#endregion CORPORATION OPERATIONS

  //#region CAMPUS OPERATIONS
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.CAMPUS_VIEWER],
    name: "Kampüs",
    icon: "/media/menuItems/42.png",
    path: "campus-operations/campus",
    component: Campus,
    menu: true,
    parentId: "campus-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Kampüs Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "campus-operations/campus/add",
    component: Campus_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Kampüs Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "campus-operations/campus/edit",
    component: Campus_CU,
    menu: false
  },
  {
    id: "6264a861ec8hw412e5078ennnb",
    code: [OperationEnum.ADDITIONAL_BUILDING_ADMIN, OperationEnum.ADDITIONAL_BUILDING_VIEWER],
    name: "Ek Bina",
    icon: "/media/menuItems/50.png",
    path: "campus-operations/additional-building",
    component: AdditionalBuilding,
    menu: true,
    parentId: "campus-operations"
  },
  {
    id: "6264a861ec804fgnfnfgn78e3bb",
    code: [OperationEnum.ADDITIONAL_BUILDING_ADMIN],
    name: "Ek Bina Ekle",
    icon: "/media/menuItems/50.png",
    path: "campus-operations/additional-building/add",
    component: AdditionalBuildingCU,
    menu: false
  },
  {
    id: "626453h3j61ec804fgnfnfgn78e3bb",
    code: [OperationEnum.ADDITIONAL_BUILDING_ADMIN],
    name: "Ek Bina Düzenle",
    icon: "/media/menuItems/50.png",
    path: "campus-operations/additional-building/edit",
    component: AdditionalBuildingCU,
    menu: false
  },
  //#endregion CAMPUS OPERATIONS

  //#region SCHOOL OPERATIONS
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.SCHOOL_VIEWER, OperationEnum.SCHOOL_EDITOR],
    name: "Kurum",
    icon: "/media/menuItems/46.png",
    path: "school-operations/school",
    component: School,
    menu: true,
    parentId: "school-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Kurum Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "school-operations/school/add",
    component: School_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Kurum Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "school-operations/school/edit",
    component: School_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ROOM_ADMIN],
    name: "Derslikler",
    icon: "/media/menuItems/50.png",
    path: "school-operations/rooms",
    component: Rooms,
    menu: true,
    parentId: "school-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ROOM_ADMIN],
    name: "Derslik Oluştur",
    icon: "/media/menuItems/50.png",
    path: "school-operations/rooms/add",
    component: Rooms_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ROOM_ADMIN],
    name: "Derslik Düzenle",
    icon: "/media/menuItems/50.png",
    path: "school-operations/rooms/edit",
    component: Rooms_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.CLUB_ADMIN, OperationEnum.CLUB_EDITOR, OperationEnum.CLUB_VIEWER],
    name: "Kulüp",
    icon: "/media/menuItems/50.png",
    path: "school-operations/club",
    component: Club,
    menu: true,
    parentId: "school-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.CLUB_ADMIN, OperationEnum.CLUB_EDITOR],
    name: "Kulüp",
    icon: "/media/menuItems/50.png",
    path: "school-operations/club/add",
    component: Club_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.CLUB_ADMIN, OperationEnum.CLUB_EDITOR],
    name: "Kulüp",
    icon: "/media/menuItems/50.png",
    path: "school-operations/club/edit",
    component: Club_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.CLASS_ADMIN, OperationEnum.CLASS_EDITOR, OperationEnum.CLASS_VIEWER],
    name: "Sınıf",
    icon: "/media/menuItems/50.png",
    path: "school-operations/classroom",
    component: Classroom,
    menu: true,
    parentId: "school-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.CLASS_ADMIN, OperationEnum.CLASS_EDITOR],
    name: "Sınıf Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "school-operations/classroom/add",
    component: Classroom_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.CLASS_ADMIN, OperationEnum.CLASS_EDITOR],
    name: "Sınıf Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "school-operations/classroom/edit",
    component: Classroom_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.GROUP_ADMIN, OperationEnum.GROUP_EDITOR, OperationEnum.GROUP_VIEWER],
    name: "Grup",
    icon: "/media/menuItems/58.png",
    path: "school-operations/group",
    component: Group,
    menu: true,
    parentId: "school-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.GROUP_ADMIN, OperationEnum.GROUP_EDITOR],
    name: "Grup Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "school-operations/group/add",
    component: Group_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.GROUP_ADMIN, OperationEnum.GROUP_EDITOR],
    name: "Grup Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "school-operations/group/edit",
    component: Group_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ETUDE_ADMIN, OperationEnum.ETUDE_VIEWER, OperationEnum.ETUDE_EDITOR],
    name: "Etüt",
    icon: "/media/menuItems/50.png",
    path: "school-operations/etude",
    component: Etude,
    menu: true,
    parentId: "school-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ETUDE_ADMIN, OperationEnum.ETUDE_EDITOR],
    name: "Etüt Ekle",
    icon: "/media/menuItems/50.png",
    path: "school-operations/etude/add",
    component: Etude_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ETUDE_ADMIN, OperationEnum.ETUDE_EDITOR],
    name: "Etüt Düzenle",
    icon: "/media/menuItems/50.png",
    path: "school-operations/etude/edit",
    component: Etude_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.FOOD_PROGRAM_ADMIN, OperationEnum.FOOD_PROGRAM_VIEWER],
    name: "Yemek Programı",
    icon: "/media/menuItems/sinav.png",
    path: "school-operations/food-program",
    component: SchoolFoodProgram,
    menu: true,
    parentId: "school-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [
      OperationEnum.CLASS_ADMIN,
      OperationEnum.CLASS_EDITOR,
      OperationEnum.CLASS_VIEWER,
      OperationEnum.ATTENDANCE_ADMIN,
      OperationEnum.ATTENDANCE_VIEWER
    ],
    name: "Yoklama",
    icon: "/media/menuItems/sinav.png",
    path: "school-operations/attendance",
    component: AttendanceSchool,
    menu: true,
    parentId: "school-operations"
  },
  //#endregion SCHOOL OPERATIONS

  //#region REGISTER OPERATIONS
  {
    id: "6264a861ec80412e5078e3bb",
    code: [
      OperationEnum.STUDENT_ADMIN,
      OperationEnum.STUDENT_EDITOR,
      OperationEnum.STUDENT_VIEWER,
      OperationEnum.STUDENT_ADDER
    ],
    name: "Öğrenci",
    icon: "/media/menuItems/22.png",
    path: "student-operations/student",
    component: Student,
    menu: true,
    parentId: "registration-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.STUDENT_ADMIN, OperationEnum.STUDENT_EDITOR, OperationEnum.STUDENT_ADDER],
    name: "Öğrenci Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "student-operations/student/add",
    component: StudentCU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [
      OperationEnum.PARENT_ADMIN,
      OperationEnum.PARENT_EDITOR,
      OperationEnum.PARENT_VIEWER,
      OperationEnum.PARENT_ADDER
    ],
    name: "Veli",
    icon: "/media/menuItems/29.png",
    path: "student-operations/conservator",
    component: Conservator,
    menu: true,
    parentId: "registration-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.PARENT_ADMIN, OperationEnum.PARENT_EDITOR, OperationEnum.PARENT_ADDER],
    name: "Veli Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "student-operations/conservator/add",
    component: ConservatorCU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.STUDENT_ADMIN, OperationEnum.STUDENT_EDITOR, OperationEnum.STUDENT_VIEWER],
    name: "YKS Sonuç İşlemleri",
    icon: "/media/menuItems/tercih.png",
    path: "student-operations/yks-evaluation-results",
    component: EvaluationResult,
    menu: true,
    parentId: "registration-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.STUDENT_ADMIN, OperationEnum.STUDENT_EDITOR, OperationEnum.STUDENT_VIEWER],
    name: "MSU Sonuç İşlemleri",
    icon: "/media/menuItems/tercih.png",
    path: "student-operations/msu-evaluation-results",
    component: EvaluationResultMSU,
    menu: true,
    parentId: "registration-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.STUDENT_ADMIN, OperationEnum.STUDENT_EDITOR, OperationEnum.STUDENT_VIEWER],
    name: "Soru Çözüm Formu",
    icon: "/media/menuItems/tercih.png",
    path: "student-operations/question-solution",
    component: QuestionSolution,
    menu: true,
    parentId: "registration-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.STUDENT_ADMIN, OperationEnum.STUDENT_EDITOR, OperationEnum.STUDENT_VIEWER],
    name: "Soru Çözüm Formu",
    icon: "/media/menuItems/tercih.png",
    path: "student-operations/question-solution/add",
    component: QuestionSolution_CU,
    menu: false,
    parentId: "registration-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.STUDENT_ADMIN, OperationEnum.STUDENT_EDITOR, OperationEnum.STUDENT_VIEWER],
    name: "Soru Çözüm Formu",
    icon: "/media/menuItems/tercih.png",
    path: "student-operations/question-solution/edit",
    component: QuestionSolution_CU,
    menu: false,
    parentId: "registration-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.STUDENT_ADMIN, OperationEnum.STUDENT_EDITOR, OperationEnum.STUDENT_VIEWER],
    name: "MSU Sonucu Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "student-operations/msu-evaluation-results/add",
    component: EvaluationResultMSU_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.STUDENT_ADMIN, OperationEnum.STUDENT_EDITOR, OperationEnum.STUDENT_VIEWER],
    name: "MSU Sonucu Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "student-operations/msu-evaluation-results/edit",
    component: EvaluationResultMSU_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.STUDENT_ADMIN, OperationEnum.STUDENT_EDITOR, OperationEnum.STUDENT_VIEWER],
    name: "Yerleştirme Sonucu Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "student-operations/yks-evaluation-results/add",
    component: EvaluationResult_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.STUDENT_ADMIN, OperationEnum.STUDENT_EDITOR, OperationEnum.STUDENT_VIEWER],
    name: "Yerleştirme Sonucu Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "student-operations/yks-evaluation-results/edit",
    component: EvaluationResult_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.PRE_REGISTRATION_ADMIN, OperationEnum.PRE_REGISTRATION_VIEWER],
    name: "Ön Kayıt",
    icon: "/media/menuItems/sinav.png",
    path: "student-operations/preregistration",
    component: PreRegistration,
    menu: true,
    parentId: "registration-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.PRE_REGISTRATION_ADMIN],
    name: "Ön Kayıt Ekle",
    icon: "/media/bottom-menu/exam.png",
    path: "student-operations/preregistration/add",
    component: PreRegistrationCU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.PRE_REGISTRATION_ADMIN],
    name: "Ön Kayıt Düzenle",
    icon: "/media/bottom-menu/exam.png",
    path: "student-operations/preregistration/edit",
    component: PreRegistrationCU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.STUDENT_ADMIN, OperationEnum.STUDENT_EDITOR],
    name: "Öğrenci Transfer Başvuruları",
    icon: "/media/menuItems/sinav.png",
    path: "student-operations/student-transfer-request",
    component: StudentTransferRequest,
    menu: true,
    parentId: "registration-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.STUDENT_ADMIN, OperationEnum.STUDENT_EDITOR],
    name: "Öğrenci Transfer Başvuruları Ekle",
    icon: "/media/menuItems/sinav.png",
    path: "student-operations/student-transfer-request/add",
    component: StudentTransferRequestCU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.STUDENT_ADMIN, OperationEnum.STUDENT_EDITOR],
    name: "Öğrenci Transfer Başvuruları Düzenle",
    icon: "/media/menuItems/sinav.png",
    path: "student-operations/student-transfer-request/edit",
    component: StudentTransferRequestCU,
    menu: false
  },
  //#endregion REGISTER OPERATIONS

  //#region PERSONNEL OPERATIONS
  {
    id: "6264a861ec80412e5078e3bb",
    code: [
      OperationEnum.PERSONNEL_ADMIN,
      OperationEnum.PERSONNEL_EDITOR,
      OperationEnum.PERSONNEL_VIEWER
    ],
    name: "Personel",
    icon: "/media/menuItems/personel.png",
    path: "personnel-operations/personel",
    component: Personel,
    menu: true,
    parentId: "personnel-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.PERSONNEL_ADMIN, OperationEnum.PERSONNEL_EDITOR],
    name: "Personel Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "personnel-operations/personel/add",
    component: PersonelCU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [
      OperationEnum.PERSONNEL_ADMIN,
      OperationEnum.PERSONNEL_EDITOR,
      OperationEnum.PERSONNEL_VIEWER
    ],
    name: "Öğretmen",
    icon: "/media/menuItems/ogretmen.png",
    path: "personnel-operations/teachers",
    component: Teacher,
    menu: true,
    parentId: "personnel-operations"
  },
  //#endregion PERSONNEL OPERATIONS

  //#region SHARE_ITEM OPERATIONS
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.COURSE_ADMIN, OperationEnum.COURSE_VIEWER],
    name: "Dersler",
    icon: "/media/menuItems/1.png",
    path: "/course-operations/course",
    component: Course,
    menu: true,
    parentId: "course-lesson-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.COURSE_ADMIN],
    name: "Ders Ekleme",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/course-operations/course/add",
    component: Course_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.COURSE_ADMIN],
    name: "Ders Düzenleme",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/course-operations/course/edit",
    component: Course_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.LIVE_COURSE_ADMIN, OperationEnum.LIVE_COURSE_VIEWER],
    name: "Canlı Dersler",
    icon: "/media/menuItems/8.png",
    path: "/course-operations/live-course",
    component: LiveCourse,
    menu: true,
    parentId: "course-lesson-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.LIVE_COURSE_ADMIN],
    name: "Canlı Ders Ekleme",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/course-operations/live-course/add",
    component: LiveCourse_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.LIVE_COURSE_ADMIN],
    name: "Canlı Ders Düzenleme",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/course-operations/live-course/edit",
    component: LiveCourse_CU,
    menu: false
  },
  {
    id: "6264a856ec80412e5078e3a7",
    code: [OperationEnum.HOMEWORK_ADMIN, OperationEnum.HOMEWORK_VIEWER],
    name: "Ödevler",
    icon: "/media/menuItems/15.png",
    path: "/course-operations/homework",
    component: Homework,
    menu: true,
    parentId: "course-lesson-operations"
  },
  {
    id: "6264a85cec80412e5078e3b1",
    code: [OperationEnum.HOMEWORK_ADMIN],
    name: "Ödev Ekleme",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/course-operations/homework/add",
    component: Homework_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.HOMEWORK_ADMIN],
    name: "Ödev Düzenleme",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/course-operations/homework/edit",
    component: Homework_CU,
    menu: false
  },
  {
    id: "6264a856ec80412e5078e3b8",
    code: [OperationEnum.LIBRARY_ADMIN, OperationEnum.LIBRARY_VIEWER],
    name: "Kütüphane",
    icon: "/media/menuItems/15.png",
    path: "/course-operations/booklibrary",
    component: Library,
    menu: true,
    parentId: "course-lesson-operations"
  },
  {
    id: "6264a856ec80412e5078e3b8",
    code: [OperationEnum.LIBRARY_ADMIN],
    name: "Kitap Ekle",
    icon: "/media/menuItems/15.png",
    path: "/course-operations/booklibrary/book-add",
    component: Library_CU,
    menu: false,
    parentId: "course-lesson-operations"
  },
  {
    id: "6264a856ec80412e5078e3b8",
    code: [OperationEnum.LIBRARY_ADMIN],
    name: "Kitap Düzenle",
    icon: "/media/menuItems/15.png",
    path: "/course-operations/booklibrary/book-edit",
    component: Library_CU,
    menu: false,
    parentId: "course-lesson-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.TEST_ADMIN, OperationEnum.TEST_VIEWER],
    name: "Testler",
    icon: "/media/menuItems/120.png",
    path: "/course-operations/test",
    component: Test,
    menu: true,
    parentId: "course-lesson-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.TEST_ADMIN],
    name: "Test Ekle",
    icon: "/media/menuItems/120.png",
    path: "/course-operations/test/add",
    component: Test_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.TEST_ADMIN],
    name: "Test Düzenle",
    icon: "/media/menuItems/120.png",
    path: "/course-operations/test/edit",
    component: Test_CU,
    menu: false
  },
  ////
  {
    id: "6264a856ec80412e50cce3a7",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "LiveExamy Yks",
    icon: "/media/menuItems/15.png",
    path: "/course-operations/live-examy-yks",
    component: LiveExamyYks,
    menu: true,
    parentId: "course-lesson-operations"
  },
  {
    id: "6264cc5cec80412e5078e3b1",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "LiveExamy Yks Ekleme",
    // icon: "/media/bottom-menu/zookeeper.png",
    path: "/course-operations/live-examy-yks/add",
    component: LiveExamyYks_CU,
    menu: false
  },
  {
    id: "6264accec80412e5078e3b1",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "LiveExamy Yks Düzenleme",
    // icon: "/media/bottom-menu/zookeeper.png",
    path: "/course-operations/live-examy-yks/edit",
    component: LiveExamyYks_CU,
    menu: false
  },
  {
    id: "6264a856ec80412e502cce312",
    code: [OperationEnum.A_TO_Z_ADMIN],
    name: "AtoZ Soruları",
    icon: "/media/menuItems/15.png",
    path: "/course-operations/atoz",
    component: AtoZ,
    menu: true,
    parentId: "course-lesson-operations"
  },
  {
    id: "6264a856ec80412e50cce312",
    code: [OperationEnum.A_TO_Z_ADMIN],
    name: "AtoZ Soruları Ekle",
    icon: "/media/menuItems/15.png",
    path: "/course-operations/atoz/add",
    component: AtoZ_CU,
    menu: false,
    parentId: "course-lesson-operations"
  },
  {
    id: "6264a856ec804121e50cce3d8",
    code: [OperationEnum.EARNING_COURSE_ADMIN],
    name: "DSD Soruları",
    icon: "/media/menuItems/15.png",
    path: "/course-operations/dsd",
    component: Dsd,
    menu: true,
    parentId: "course-lesson-operations"
  },
  {
    id: "6264a856ec80412e50cce3d8",
    code: [OperationEnum.EARNING_COURSE_ADMIN],
    name: "DSD Soruları Ekle",
    icon: "/media/menuItems/15.png",
    path: "/course-operations/dsd/add",
    component: Dsd_CU,
    menu: false,
    parentId: "course-lesson-operations"
  },
  ////
  {
    id: "6264a856ec80412e5078e3a7",
    code: [OperationEnum.BASIC_HOMEWORK_ADMIN, OperationEnum.BASIC_HOMEWORK_VIEWER],
    name: "Ev Ödevleri",
    icon: "/media/menuItems/15.png",
    path: "/course-operations/basic-homework",
    component: BasicHomework,
    menu: true,
    parentId: "course-lesson-operations"
  },
  {
    id: "6264a85cec80412e5078e3b1",
    code: [OperationEnum.BASIC_HOMEWORK_ADMIN],
    name: "Ev Ödevi Ekleme",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/course-operations/basic-homework/add",
    component: BasicHomework_CU,
    menu: false
  },
  {
    id: "6264a85cec80412e5078e3b1",
    code: [OperationEnum.BASIC_HOMEWORK_ADMIN],
    name: "Ev Ödevi Düzenleme",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/course-operations/basic-homework/edit",
    component: BasicHomework_CU,
    menu: false
  },
  {
    id: "6264a856ec80412e3478e337",
    code: [OperationEnum.OUTSIDE_VIEWER],
    name: "Outside",
    icon: "/media/bottom-menu/outside.png",
    path: "/course-operations/outside",
    component: Outside,
    menu: true,
    parentId: "course-lesson-operations"
  },
  {
    id: "6264a856ec80412e3478e337",
    code: [OperationEnum.COURSE_ADMIN],
    name: "Video Değerlendirmeleri",
    icon: "",
    path: "/course-operations/videofeedback",
    component: VideoFeedbackAdmin,
    menu: true,
    parentId: "course-lesson-operations",
    permissionFun: (auth) => auth?.roleId === "6264a458ec80412e5078e1e5"
  },
  //#endregion SHARE_ITEM OPERATIONS

  //#region EXAM OPERATIONS
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SUPER_EXAM_ADMIN, OperationEnum.EXAM_ADMIN, OperationEnum.EXAM_EDITOR],
    name: "Optik Form",
    icon: "/media/menuItems/optik-form.png",
    path: "/exam-operations/optical-form",
    component: OpticalForm,
    menu: true,
    parentId: "exam-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SUPER_EXAM_ADMIN, OperationEnum.EXAM_ADMIN, OperationEnum.EXAM_EDITOR],
    name: "Sınav Şablon",
    icon: "/media/menuItems/sinav-sablon.png",
    path: "/exam-operations/exam-template",
    component: ExamTemplate,
    menu: true,
    parentId: "exam-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SUPER_EXAM_ADMIN, OperationEnum.EXAM_ADMIN, OperationEnum.EXAM_EDITOR],
    name: "Sınav Şablon Ekleme",
    icon: "/media/bottom-menu/exam-template.png",
    path: "/exam-operations/exam-template/add",
    component: ExamTemplate_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SUPER_EXAM_ADMIN, OperationEnum.EXAM_ADMIN, OperationEnum.EXAM_EDITOR],
    name: "Sınav Şablon Düzenleme",
    icon: "/media/bottom-menu/exam-template.png",
    path: "/exam-operations/exam-template/edit",
    component: ExamTemplate_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [],
    name: "Pdf Projelerim",
    icon: "/media/menuItems/pdf-projelerim.png",
    path: "/exam-operations/pdf-project",
    component: PdfProject,
    menu: true,
    parentId: "exam-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SUPER_EXAM_ADMIN],
    name: "Pdf Kırpma",
    icon: "/media/menuItems/pdf-kirpma.png",
    path: "/exam-operations/pdf-crop",
    component: PdfCrop,
    menu: true,
    parentId: "exam-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SUPER_EXAM_ADMIN, OperationEnum.QUESTION_POOL_ADMIN],
    name: "Soru Havuzu",
    icon: "/media/menuItems/soru-havuzu.png",
    path: "/exam-operations/question-pool",
    component: QuestionPool,
    menu: true,
    parentId: "exam-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SUPER_EXAM_ADMIN, OperationEnum.QUESTION_POOL_ADMIN],
    name: "Soru Havuzu v2",
    icon: "/media/menuItems/soru-havuzu.png",
    path: "/exam-operations/question-pool-v2",
    component: QuestionPoolV2,
    menu: true,
    parentId: "exam-operations"
  },
  {
    id: "6264b5dcc783c144a031a7651",
    code: [OperationEnum.SUPER_EXAM_ADMIN, OperationEnum.QUESTION_POOL_ADMIN],
    name: "Kitaplar",
    icon: "/media/menuItems/question-books.png",
    path: "/exam-operations/question-books",
    component: QuestionBooks,
    menu: true,
    parentId: "exam-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [],
    name: "Soru Ekleme",
    icon: "/media/bottom-menu/question.png",
    path: "/exam-operations/question/add",
    component: Question_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [],
    name: "Soru Düzenleme",
    icon: "/media/bottom-menu/question.png",
    path: "/exam-operations/question/edit",
    component: Question_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [],
    name: "Soru Sepeti",
    icon: "/media/bottom-menu/question.png",
    path: "/exam-operations/question-cart",
    component: QuestionCart,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [
      OperationEnum.SUPER_EXAM_ADMIN,
      OperationEnum.EXAM_ADMIN,
      OperationEnum.EXAM_EDITOR,
      OperationEnum.EXAM_VIEWER
    ],
    name: "Sınavlar",
    icon: "/media/menuItems/sinav.png",
    path: "/exam-operations/exam",
    component: Exam,
    menu: true,
    parentId: "exam-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SUPER_EXAM_ADMIN, OperationEnum.EXAM_ADMIN, OperationEnum.EXAM_EDITOR],
    name: "Sınav Ekle",
    icon: "/media/bottom-menu/exam.png",
    path: "/exam-operations/exam/add",
    component: Exam_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SUPER_EXAM_ADMIN, OperationEnum.EXAM_ADMIN, OperationEnum.EXAM_EDITOR],
    name: "Sınav Düzenle",
    icon: "/media/bottom-menu/exam.png",
    path: "/exam-operations/exam/edit",
    component: Exam_CU,
    menu: false,
    parentId: "exam-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SUPER_EXAM_ADMIN, OperationEnum.EXAM_ADMIN, OperationEnum.EXAM_EDITOR],
    name: "Sınav Değerlendirme",
    icon: "/media/bottom-menu/exam.png",
    path: "/exam-operations/exam-evaluation",
    component: ExamEvaluation,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ETUDE_ADMIN, OperationEnum.ETUDE_EDITOR],
    name: "Etüt Oluştur",
    icon: "/media/bottom-menu/exam.png",
    path: "/exam-operations/exam-etude",
    component: ExamEtude,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [],
    name: "Sınav Raporları",
    icon: "/media/menuItems/tahsilat.png",
    path: "/exam-operations/exam-report",
    component: ExamReport,
    menu: true,
    parentId: "exam-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.SUPER_EXAM_ADMIN, OperationEnum.EXAM_ADMIN, OperationEnum.EXAM_EDITOR],
    name: "Geçmiş Dat Dosyaları",
    icon: "/media/menuItems/optik-form.png",
    path: "/exam-operations/dat-history",
    component: DatHistory,
    menu: true,
    parentId: "exam-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.SITTING_PLAN_ADMIN],
    name: "Oturma Planları",
    icon: "/media/menuItems/50.png",
    path: "exam-operations/sittingplan",
    component: SittingPlan,
    menu: true,
    parentId: "exam-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.SITTING_PLAN_ADMIN],
    name: "Oturma Planı Ekle",
    icon: "/media/menuItems/50.png",
    path: "exam-operations/sittingplan/add",
    component: SittingPlan_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.SITTING_PLAN_ADMIN],
    name: "Oturma Planı Düzenle",
    icon: "/media/menuItems/50.png",
    path: "exam-operations/sittingplan/edit",
    component: SittingPlan_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.SUPER_EXAM_ADMIN],
    name: "Tüm Sınav Sonuçları",
    icon: "/media/menuItems/sinav.png",
    path: "exam-operations/all-results",
    component: AllExamResults,
    menu: true,
    parentId: "exam-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.SUPER_EXAM_ADMIN, OperationEnum.EXAM_ADMIN, OperationEnum.EXAM_EDITOR],
    name: "Seans İşlemleri",
    icon: "/media/menuItems/sinav.png",
    path: "exam-operations/session-operations",
    component: Session,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.SUPER_EXAM_ADMIN, OperationEnum.EXAM_ADMIN, OperationEnum.EXAM_EDITOR],
    name: "Seans Ekle",
    icon: "/media/menuItems/sinav.png",
    path: "exam-operations/session-operations/session-add",
    component: Session_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.SUPER_EXAM_ADMIN, OperationEnum.EXAM_ADMIN, OperationEnum.EXAM_EDITOR],
    name: "Seans Düzenle",
    icon: "/media/menuItems/sinav.png",
    path: "exam-operations/session-operations/session-edit",
    component: Session_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078444444",
    code: [OperationEnum.SUPER_EXAM_ADMIN],
    name: "Bursluluk Sınavı Raporları",
    icon: "/media/menuItems/sinav.png",
    path: "exam-operations/session-reports",
    component: SessionReports,
    menu: true,
    parentId: "exam-operations"
  },
  // #endregion EXAM OPERATIONS

  //#region CONTRACT OPERATIONS
  {
    id: "6264b5dcc783c144a031a765",
    code: [
      OperationEnum.FINANCIAL_YEAR_ADMIN,
      OperationEnum.FINANCIAL_YEAR_VIEWER,
      OperationEnum.FINANCIAL_YEAR_EDITOR
    ],
    name: "Mali Yıl",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/contract-operations/financial-year",
    component: FinancialYear,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [
      OperationEnum.FINANCIAL_ACCOUNT_ADMIN,
      OperationEnum.FINANCIAL_ACCOUNT_VIEWER,
      OperationEnum.FINANCIAL_ACCOUNT_EDITOR
    ],
    name: "Mali Hesap",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/contract-operations/financial-account",
    component: FinancialAccount,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [
      OperationEnum.FEE_ITEM_ADMIN,
      OperationEnum.FEE_ITEM_VIEWER,
      OperationEnum.FEE_ITEM_EDITOR
    ],
    name: "Ücret Kalemi",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/contract-operations/fee-item",
    component: FeeItem,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.FEE_ITEM_ADMIN, OperationEnum.FEE_ITEM_EDITOR],
    name: "Ücret Kalemi Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/contract-operations/fee-item/add",
    component: FeeItem_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.FEE_ITEM_ADMIN, OperationEnum.FEE_ITEM_EDITOR],
    name: "Ücret Kalemi Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/contract-operations/fee-item/edit",
    component: FeeItem_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [
      OperationEnum.FEE_ITEM_SET_ADMIN,
      OperationEnum.FEE_ITEM_SET_VIEWER,
      OperationEnum.FEE_ITEM_SET_EDITOR
    ],
    name: "Ücret Grubu",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/contract-operations/fee-item-set",
    component: FeeItemSet,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.FEE_ITEM_SET_ADMIN, OperationEnum.FEE_ITEM_SET_EDITOR],
    name: "Ücret Grubu Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/contract-operations/fee-item-set/add",
    component: FeeItemSet_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.FEE_ITEM_SET_ADMIN, OperationEnum.FEE_ITEM_SET_EDITOR],
    name: "Ücret Grubu Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/contract-operations/fee-item-set/edit",
    component: FeeItemSet_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [
      OperationEnum.DISCOUNT_ITEM_ADMIN,
      OperationEnum.DISCOUNT_ITEM_VIEWER,
      OperationEnum.DISCOUNT_ITEM_EDITOR
    ],
    name: "İndirim Kalemi",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/contract-operations/discount-item",
    component: DiscountItem,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.DISCOUNT_ITEM_ADMIN, OperationEnum.DISCOUNT_ITEM_EDITOR],
    name: "İndirim Kalemi Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/contract-operations/discount-item/add",
    component: DiscountItem_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.DISCOUNT_ITEM_ADMIN, OperationEnum.DISCOUNT_ITEM_EDITOR],
    name: "İndirim Kalemi Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/contract-operations/discount-item/edit",
    component: DiscountItem_CU,
    menu: false
  },
  {
    id: "60ecb5dcc783c144a031a700",
    code: [OperationEnum.CASE_OPERATION_ADMIN], // Yetkilere göre düzenlemesi yapılacak
    name: "Kasa İşlemleri",
    icon: "", // Menü icon'u eklenecek
    path: "/contract-operations/case-operations",
    component: CaseOperations,
    menu: true,
    parentId: "accounting-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [
      OperationEnum.CONTRACT_ADMIN,
      OperationEnum.CONTRACT_EDITOR,
      OperationEnum.CONTRACT_VIEWER,
      OperationEnum.CONTRACT_ADDER,
      OperationEnum.PAYMENT_ADDER
    ],
    name: "Sözleşme",
    icon: "/media/menuItems/sozlesme.png",
    path: "/contract-operations/contract",
    component: Contract,
    menu: true,
    parentId: "accounting-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [
      OperationEnum.CONTRACT_ADMIN,
      OperationEnum.CONTRACT_EDITOR,
      OperationEnum.CONTRACT_ADDER
    ],
    name: "Sözleşme Ekle",
    icon: "/media/bottom-menu/exam.png",
    path: "/contract-operations/contract/add",
    component: Contract_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.CONTRACT_ADMIN, OperationEnum.CONTRACT_EDITOR],
    name: "Sözleşme Düzenle",
    icon: "/media/bottom-menu/exam.png",
    path: "/contract-operations/contract/edit",
    component: Contract_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [
      OperationEnum.PAYMENT_ADMIN,
      OperationEnum.PAYMENT_EDITOR,
      OperationEnum.PAYMENT_VIEWER,
      OperationEnum.PAYMENT_ADDER
    ],
    name: "Tahsilat",
    icon: "/media/menuItems/tahsilat.png",
    path: "/contract-operations/payment",
    component: Payment,
    menu: true,
    parentId: "accounting-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.PAYMENT_ADMIN, OperationEnum.PAYMENT_EDITOR, OperationEnum.PAYMENT_ADDER],
    name: "Tahsilat Ekle",
    icon: "/media/bottom-menu/exam.png",
    path: "/contract-operations/payment/add",
    component: Payment_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.PAYMENT_ADMIN, OperationEnum.PAYMENT_EDITOR],
    name: "Tahsilat Düzenle",
    icon: "/media/bottom-menu/exam.png",
    path: "/contract-operations/payment/edit",
    component: Payment_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.PAYMENT_ADMIN, OperationEnum.PAYMENT_EDITOR, OperationEnum.PAYMENT_ADDER],
    name: "Tahsilat Ekle",
    icon: "/media/bottom-menu/exam.png",
    path: "/contract-operations/payment/add/multiple",
    component: MultipleContractPayment_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.CONTRACT_REPORT_VIEWER],
    name: "Sözleşme Raporları",
    icon: "/media/menuItems/sozlesme-raporlari.png",
    path: "/contract-operations/contract-report",
    component: ContractReport,
    menu: true,
    parentId: "accounting-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.CONTRACT_PAYMENT_REPORT_VIEWER],
    name: "Tahsilat Raporları",
    icon: "/media/menuItems/tahsilat-raporlari.png",
    path: "/contract-operations/payment-report",
    component: PaymentReport,
    menu: true,
    parentId: "accounting-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.CONTRACT_QUOTA_REPORT_VIEWER],
    name: "Kontenjan Raporları",
    icon: "/media/menuItems/kontenjan-raporlari.png",
    path: "/contract-operations/quota-report",
    component: QuotaReport,
    menu: true,
    parentId: "accounting-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.LOG_VIEWER],
    name: "Log Kayıtları",
    icon: "/media/menuItems/log-kayitlari.png",
    path: "/contract-operations/contract-log",
    component: ContractLog,
    menu: true,
    parentId: "accounting-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [
      OperationEnum.CONTRACT_ADMIN,
      OperationEnum.CONTRACT_EDITOR,
      OperationEnum.OVERDUE_INSTALLMENTS
    ],
    name: "Gecikmiş Vadeler",
    icon: "/media/menuItems/sozlesme.png",
    path: "/contract-operations/overdue-installments",
    component: OverdueInstallments,
    menu: true,
    parentId: "accounting-operations"
  },
  //#endregion CONTRACT OPERATIONS

  //#region ANOUNCEMENT OPERATIONS
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ANNOUNCEMENT_ADMIN, OperationEnum.COMMON_CONSERVATOR],
    name: "SMS",
    icon: "/media/svg/icons/message.svg",
    path: "message-operations/inbox",
    component: Message,
    menu: true,
    topMenuParentId: "top-menu-message-operations"
  },
  //#endregion ANOUNCEMENT OPERATIONS

  //#region TRACKLIGHT OPERATIONS
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.TRACKLIGHT_ADMIN],
    name: "Karne",
    icon: "/media/menuItems/tracklight-karne.png",
    path: "tracklight-operations/tracklight",
    component: TrackLightReportCard,
    menu: true,
    parentId: "tracklight-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.TRACKLIGHT_ADMIN],
    name: "Karne Ekle",
    icon: "/media/bottom-menu/tracklight-karne.png",
    path: "tracklight-operations/tracklight/add",
    component: TrackLightReportCard_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.TRACKLIGHT_ADMIN],
    name: "Karne Düzenle",
    icon: "/media/bottom-menu/tracklight.png",
    path: "tracklight-operations/tracklight/edit",
    component: TrackLightReportCard_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.TRACKLIGHT_ADMIN],
    name: "Kazanım",
    icon: "/media/menuItems/kazanim.png",
    path: "/tracklight-operations/earning",
    component: TrackLightEarning,
    menu: true,
    parentId: "tracklight-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.TRACKLIGHT_ADMIN],
    name: "Kazanım Ekle",
    icon: "/media/bottom-menu/tracklight-earning.png",
    path: "/tracklight-operations/earning/add",
    component: TrackLightEarning_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.TRACKLIGHT_ADMIN],
    name: "Kazanım Düzenle",
    icon: "/media/bottom-menu/tracklight-earning.png",
    path: "/tracklight-operations/earning/edit",
    component: TrackLightEarning_CU,
    menu: false
  },
  //#endregion TRACKLIGHT OPERATIONS

  //#region CONTENT_PRODUCER OPERATIONS
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "İçerik Üreticisi",
    icon: "/media/menuItems/76.png",
    path: "content-producer-operations/content-producer",
    component: ContentProducer,
    menu: true,
    parentId: "content-producer-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "İçerik Üreticisi Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "content-producer-operations/content-producer/add",
    component: ContentProducer_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "İçerik Üreticisi Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "content-producer-operations/content-producer/edit",
    component: ContentProducer_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "İçerik Üretici Kullanıcı Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/content-producer/user-add",
    component: ContentProducerUser_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "İçerik Üretici Kullanıcı Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/content-producer/user-edit",
    component: ContentProducerUser_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.SHARE_PACK_ADMIN, OperationEnum.SHARE_PACK_VIEWER],
    name: "İçerik Paketleri",
    icon: "/media/menuItems/84.png",
    path: "content-producer-operations/share-pack",
    component: SharePack,
    menu: true,
    parentId: "content-producer-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.SHARE_PACK_ADMIN],
    name: "İçerik Paketi Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "content-producer-operations/share-pack/add",
    component: SharePack_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.SHARE_PACK_ADMIN],
    name: "İçerik Paketi Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "content-producer-operations/share-pack/edit",
    component: SharePack_CU,
    menu: false
  },
  //#endregion CONTENT_PRODUCER OPERATIONS

  //#region DEFINITION_OPERATIONS
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.CURRICULUM_ADMIN],
    name: "Müfredat",
    icon: "/media/menuItems/92.png",
    path: "definitions-operations/curriculum",
    component: Curriculum,
    menu: true,
    parentId: "definitions-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Rol",
    icon: "/media/svg/icons/role.svg",
    path: "definitions-operations/role",
    component: Role,
    menu: true,
    parentId: "role-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Rol",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "definitions-operations/role/add",
    component: Role_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Rol",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "definitions-operations/role/edit",
    component: Role_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Yetkilendirme",
    icon: "/media/menuItems/105.png",
    path: "definitions-operations/operation",
    component: Operation,
    menu: true,
    parentId: "role-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Yetkilendirme Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "definitions-operations/operation/add",
    component: Operation_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Yetkilendirme Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "definitions-operations/operation/edit",
    component: Operation_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Kademe",
    icon: "/media/menuItems/112.png",
    path: "definitions-operations/level",
    component: Level,
    menu: true,
    parentId: "definitions-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Kademe Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "definitions-operations/level/add",
    component: Level_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Kademe Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "definitions-operations/level/edit",
    component: Level_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Temel Branş",
    icon: "/media/menuItems/116.png",
    path: "definitions-operations/basebranch",
    component: BaseBranch,
    menu: true,
    parentId: "definitions-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Temel Branş Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "definitions-operations/basebranch/add",
    component: BaseBracnh_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Temel Branş Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "definitions-operations/basebranch/edit",
    component: BaseBracnh_CU,
    menu: false
  },
  //TODO: personel rollerini düzenle
  {
    id: "9864b5dcc783c144a031a320",
    code: [OperationEnum.SELFID_ADMIN],
    name: "Yıllık Program",
    icon: "/media/svg/topMenu/applications.svg",
    path: "/self-id/curriculum",
    component: SelfIdCurriculum,
    menu: true,
    parentId: "self-id"
  },
  {
    id: "9864b5dcc783c144a031a320",
    code: [OperationEnum.COMMON_PERSONNEL],
    name: "Öğrenci Takibi",
    icon: "/media/svg/topMenu/applications.svg",
    path: "/self-id/student-track",
    component: SelfIdStudentTrack,
    menu: true,
    parentId: "self-id"
  },
  {
    id: "9864b5dcc783c144a031a320",
    code: [OperationEnum.SELFID_ADMIN],
    name: "Görevler Listesi",
    icon: "/media/svg/topMenu/applications.svg",
    path: "/self-id/task-list",
    component: SelfIdTaskList,
    menu: true,
    parentId: "self-id"
  },
  {
    id: "9864b5dcc783c144a031a111",
    code: [OperationEnum.COMMON_PERSONNEL],
    name: "Self ID Akademik Takvim Ekle",
    icon: "/media/svg/topMenu/applications.svg",
    path: "/self-id/add-curriculum",
    component: SelfIdCurriculumCU,
    menu: false,
    common: false
    // showParent: true
  },
  {
    id: "9864b5dcc783c144a031a000",
    code: [OperationEnum.COMMON_PERSONNEL],
    name: "Self ID Akademik Takvim Düzenle",
    icon: "/media/svg/topMenu/applications.svg",
    path: "/self-id/edit-curriculum",
    component: SelfIdCurriculumCU,
    menu: false,
    common: false
    // showParent: true
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.INTERVIEW_ADMIN, OperationEnum.INTERVIEW_VIEWER],
    name: "Rehberlik",
    icon: "/media/menuItems/120.png",
    path: "counselling-operations/counselling",
    component: Counselling,
    menu: true,
    topMenuParentId: "top-menu-counselling-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.INTERVIEW_ADMIN],
    name: "Rehberlik Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "counselling-operations/counselling/add",
    component: Counselling_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.INTERVIEW_ADMIN],
    name: "Rehberlik Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "counselling-operations/counselling/edit",
    component: Counselling_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.INTERVIEW_ADMIN],
    name: "Rehberlik Detayı Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "counselling-operations/counselling-details/add",
    component: Counselling_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.INTERVIEW_ADMIN],
    name: "Rehberlik Detayı Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "counselling-operations/counselling-details/edit",
    component: Counselling_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Branş",
    icon: "/media/menuItems/120.png",
    path: "definitions-operations/branch",
    component: Branch,
    menu: true,
    parentId: "definitions-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Branş Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "definitions-operations/branch/add",
    component: Branch_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Branş Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "definitions-operations/branch/edit",
    component: Branch_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ACADEMIC_CALENDAR_ADMIN],
    name: "Akademik Takvim Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/academic-calendar/add",
    component: AcademicCalendar_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ACADEMIC_CALENDAR_ADMIN],
    name: "Akademik Takvim Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/academic-calendar/edit",
    component: AcademicCalendar_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.PDR_ADMIN],
    name: "Envanter ve Formlar",
    icon: "/media/menuItems/120.png",
    path: "counselling-operations/pdr",
    component: PDR,
    menu: true,
    topMenuParentId: "top-menu-counselling-operations"
  },
  //#endregion DEFINITION_OPERATIONS

  //#region SURVEY_OPERATIONS

  // survey questions
  {
    id: "6264b5dcc783c144a031a765",
    code: [
      OperationEnum.ADMIN_OPERATION,
      OperationEnum.SURVEY_ADMIN,
      OperationEnum.SURVEY_EDITOR,
      OperationEnum.INVENTORY_ADMIN
    ],
    name: "Anket İşlemleri",
    icon: "/media/menuItems/92.png",
    path: "/survey",
    component: EvalutionSurvey,
    menu: true,
    topMenuParentId: "top-menu-counselling-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Sorular",
    icon: "/media/menuItems/92.png",
    path: "/pdr-form-template-cu",
    component: PDRFormTemplate_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765333",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Değerlendirme Form Listesi",
    icon: "/media/menuItems/92.png",
    path: "/pdr-forms-list",
    component: PDRFormList,
    menu: true,
    topMenuParentId: "top-menu-counselling-operations"
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Anket Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/survey/add",
    component: EvalutionSurvey_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Anket Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/survey/edit",
    component: EvalutionSurvey_CU,
    menu: false
  },

  // options
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Anket Seçenekleri",
    icon: "/media/menuItems/120.png",
    path: "/survey/option",
    component: SurveyOption,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Anket Seçenek Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/survey/option/add",
    component: SurveyOption_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Anket Seçenek Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/survey/option/edit",
    component: SurveyOption_CU,
    menu: false
  },

  // opiton sets
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Anket Seçenek Grupları",
    icon: "/media/menuItems/120.png",
    path: "/survey/option-set",
    component: SurveyOptionSet,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Anket Seçenek Grup Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/survey/option-set/add",
    component: SurveyOptionSet_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Anket Seçenek Grup Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/survey/option-set/edit",
    component: SurveyOptionSet_CU,
    menu: false
  },

  // survey questions
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Anket Soruları",
    icon: "/media/menuItems/120.png",
    path: "/survey/question",
    component: SurveyQuestion,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Anket Sorusu Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/survey/question/add",
    component: SurveyQuestion_CU,
    menu: false
  },
  {
    id: "6264b5dcc783c144a031a765",
    code: [OperationEnum.ADMIN_OPERATION],
    name: "Anket Sorusu Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "/survey/question/edit",
    component: SurveyQuestion_CU,
    menu: false
  },
  //#endregion SURVEY_OPERATIONS

  //#region INSTANT_MESSAGE
  {
    id: "264b5dcc783c1441fh3ha765",
    code: [OperationEnum.COMMON_PERSONNEL],
    name: "Anlık Mesajlaşma",
    icon: "/media/menuItems/...png",
    path: "/instant-message",
    component: InstantMessage,
    menu: true,
    topMenuParentId: "top-menu-message-operations"
  },
  //#endregion INSTANT_MESSAGE

  {
    id: "264b5dcc7321aacc1ff3ha765",
    code: [OperationEnum.PDR_ADMIN],
    name: "Kişilik Envanteri",
    icon: "/media/menuItems/...png",
    path: "/personality-survey",
    component: PersonalitySurvey,
    menu: true,
    topMenuParentId: "top-menu-counselling-operations"
  },
  {
    id: "999b5dcc7321aacc1ff3ha765",
    code: [OperationEnum.PDR_ADMIN],
    name: "Kişilik Envanteri Ekle",
    icon: "/media/menuItems/...png",
    path: "/personality-survey/add",
    component: PersonalitySurveyCU,
    menu: false,
    topMenuParentId: "top-menu-counselling-operations"
  },
  {
    id: "999b5dcc7321aacc1ff3ha765",
    code: [OperationEnum.PDR_ADMIN],
    name: "Kişilik Envanteri Ekle",
    icon: "/media/menuItems/...png",
    path: "/personality-survey/edit",
    component: PersonalitySurveyCU,
    menu: false,
    topMenuParentId: "top-menu-counselling-operations"
  }
];

const NearPodRouteList = [
  {
    id: "6264a856ec80412e5078e3a7",
    code: [OperationEnum.NEAR_POD_ADMIN],
    name: "NearPod Ödevleri",
    icon: "/media/svg/topMenu/course.svg",
    path: "/dashboard",
    component: NearpodHomework,
    menu: true
  },
  {
    id: "6264a856ec80412e5078e3a7",
    code: [OperationEnum.NEAR_POD_ADMIN],
    name: "NearPod Ödevi Ekle",
    icon: "/media/menuItems/1.png",
    path: "/course-operations/nearpod-homework/add",
    component: NearpodHomework_CU,
    menu: false
  },
  {
    id: "6264a856ec80412e5078e3a7",
    code: [OperationEnum.NEAR_POD_ADMIN],
    name: "NearPod Ödevi Düzenle",
    icon: "/media/menuItems/1.png",
    path: "/course-operations/nearpod-homework/edit",
    component: NearpodHomework_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.GROUP_ADMIN, OperationEnum.GROUP_EDITOR, OperationEnum.GROUP_VIEWER],
    name: "Grup",
    icon: "/media/menuItems/58.png",
    path: "school-operations/group",
    component: Group,
    menu: true,
    parentId: "school-operations"
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.GROUP_ADMIN, OperationEnum.GROUP_EDITOR],
    name: "Grup Ekle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "school-operations/group/add",
    component: Group_CU,
    menu: false
  },
  {
    id: "6264a861ec80412e5078e3bb",
    code: [OperationEnum.GROUP_ADMIN, OperationEnum.GROUP_EDITOR],
    name: "Grup Düzenle",
    icon: "/media/bottom-menu/zookeeper.png",
    path: "school-operations/group/edit",
    component: Group_CU,
    menu: false
  }
];
Object.freeze(RouteList);

export { RouteList, NearPodRouteList };
