const RoleEnum = {
  ADMIN: "ADMIN",
  STUDENT: "STUDENT",
  CONSERVATOR: "CONSERVATOR"
};

const RoleTypeEnum = {
  ADMIN: "ADMIN",
  PERSONNEL: "PERSONNEL",
  STUDENT: "STUDENT",
  CONSERVATOR: "CONSERVATOR",
  CONTENT_PRODUCER: "CONTENT_PRODUCER"
};

const RoleStatus = [
  { id: 1, name: "Aktif" },
  { id: 0, name: "Pasif" }
];
const RegisterType = [
  { id: 1, name: "Asil" },
  { id: 0, name: "Misafir" }
];

const RegisterStatus = [
  { id: true, name: "İç Kayıt" }, // internal
  { id: false, name: "Dış Kayıt" } // external
];

Object.freeze(RoleEnum);
Object.freeze(RoleTypeEnum);

export { RoleEnum, RoleTypeEnum, RoleStatus, RegisterType, RegisterStatus };
